<template>
  <div class="cart center-wrap">
    <div class="big-box">
      <div class="my-cart">
        <h1 v-text="$t('cart.my')">我的购物车</h1>
        <div class="shopping-cart">
          <div class="cart-title">
            <div class="click">
              <img
                src="../assets/icon/yes-select-two.png"
                v-if="selectAll"
                @click.stop="selectIdList=[]"
              />
              <img src="../assets/icon/no-select-two.png" v-else @click.stop="fnSelectAll" />
              <p v-text="$t('cart.all')">全选</p>
            </div>
            <p class="book-name" v-text="$t('cart.name')">商品名称</p>
            <p class="parameter" v-text="$t('cart.spec')">规格</p>
            <p class="parameter" v-text="$t('cart.price')">价格</p>
            <p class="parameter" v-text="$t('cart.number')">数量</p>
            <p class="parameter" v-text="$t('cart.total')">合计</p>
          </div>
          <div v-if="enable && enable.length">
            <div class="order" v-for="item,index in enable">
              <img
                src="../assets/icon/yes-select-two.png"
                class="choice-botton click"
                v-if="itemIsSelect(item.id)"
                @click.stop="unSelect(item.id)"
              />
              <img
                src="../assets/icon/no-select-two.png"
                class="choice-botton click"
                v-else
                @click.stop="fnSelect(item.id)"
              />
              <img
                :src="item.specs.image"
                class="book-photo click"
                @click.stop="toProductDetailPage(item.goods_id)"
              />
              <div class="parameter-body">
                <div class="name-box">
                  <p
                    class="click"
                    v-text="item.goods.name"
                    @click.stop="toProductDetailPage(item.goods_id)"
                  >不可撤销系列丛书系列丛书</p>
                  <div v-if="item.goods_discount" v-text="item.goods_discount.title">满300减30</div>
                </div>
                <p>{{item.spec?item.spec.name:item.specs_name}}</p>
                <div class="much">
                  <div>{{common.showPrice(item.price,item.price_type)}}</div>
                  <p
                    v-if="userinfo &&  item.price_type != userinfo.local && userinfo.local == 1"
                  >{{common.showPrice(common.div(item.price,rate),userinfo.local)}}</p>
                  <p
                    v-if="userinfo &&  item.price_type != userinfo.local && userinfo.local == 2"
                  >{{common.showPrice(common.mul(item.price,rate),userinfo.local)}}</p>
                </div>
                <div class="reduce-add">
                  <img
                    src="../assets/icon/reduce-botton.png"
                    class="click"
                    @click.stop="changeItem('sub',item,index)"
                  />
                  <!-- <p>{{item.number}}</p> -->
                  <input
                    type
                    v-model="item.number"
                    maxlength="10"
                    v-on:input="fnNumberChange(index)"
                    @blur="fnNumberBlur(index)"
                  />
                  <img
                    src="../assets/icon/add-botton.png"
                    class="click"
                    @click.stop="changeItem('add',item,index)"
                  />
                </div>
                <div class="common-much">
                  <div>{{common.showPrice(item.total,item.price_type)}}</div>
                  <!-- 单价 * 数量 * 汇率 -->
                  <!-- <p v-if="userinfo &&  item.price_type != userinfo.local && userinfo.local == 1">{{common.showPrice(common.div(item.price * item.number,rate),userinfo.local)}}</p>
                  <p v-if="userinfo &&  item.price_type != userinfo.local && userinfo.local == 2">{{common.showPrice(common.mul(item.price * item.number,rate),userinfo.local)}}</p>-->
                  <!-- 总价 * 汇率 -->
                  <p
                    v-if="userinfo &&  item.price_type != userinfo.local && userinfo.local == 1"
                  >{{common.showPrice(common.div(item.total,rate),userinfo.local)}}</p>
                  <p
                    v-if="userinfo &&  item.price_type != userinfo.local && userinfo.local == 2"
                  >{{common.showPrice(common.mul(item.total,rate),userinfo.local)}}</p>
                </div>
              </div>
              <img
                src="../assets/icon/delete.png"
                class="delete click"
                @click.stop="delItem(item,index,'enable')"
              />
            </div>
          </div>
          <div class="empty" v-if="enable.length == 0 && disable.length == 0">
            <img src="../assets/icon/cart-empty.png" alt />
            <p>{{$t('cart.empty')}}</p>
          </div>
        </div>
      </div>
      <!-- 已经失效商品 -->
      <div class="trash" v-if="disable && disable.length">
        <div class="trash-title">
          <p v-text="$t('cart.over')">已失效商品</p>
          <div class="click">
            <img src="../assets/icon/trash.png" />
            <p v-text="$t('cart.over_clear')" @click.stop="clearOverItem">清空失效商品</p>
          </div>
        </div>
        <div class="trash-order">
          <!-- 订单 -->
          <div class="order" v-for="item,index in disable">
            <img src="../assets/icon/no-select-two.png" class="choice-botton" />
            <div class="book-photo-box">
              <img :src="item.goods_image" class="book-photo" />
              <p v-text="$t('cart.off_shelf')">已下架</p>
            </div>
            <div class="parameter-body">
              <div class="name-box">
                <p class v-text="item.goods_name"></p>
                <div v-if="item.goods_discount" v-text="item.goods_discount.title">满300减30</div>
              </div>
              <p>{{item.spec?item.spec.name:item.specs_name}}</p>
              <div class="much">
                <div v-if="item.price_type == 1">¥{{item.price}}</div>
                <div v-if="item.price_type == 2">HK${{item.price}}</div>
                <p
                  v-if="userinfo &&  item.price_type != userinfo.local"
                >{{userinfo.local==1?'HK$':'¥'}} {{common.mul(item.price,rate)}}</p>
              </div>
              <div class="reduce-add">
                <img src="../assets/icon/reduce-botton.png" />
                <p>{{item.number}}</p>
                <img src="../assets/icon/add-botton.png" />
              </div>
              <div class="common-much">
                <div v-if="item.price_type == 1">¥{{item.total}}</div>
                <div v-if="item.price_type == 2">HK${{item.total}}</div>
                <p
                  v-if="userinfo &&  item.price_type != userinfo.local"
                >{{userinfo.local==1?'HK$':'¥'}} {{common.mul(item.price,rate)}}</p>
              </div>
            </div>
            <img
              src="../assets/icon/delete.png"
              class="delete click"
              @click.stop="delItem(item,index,'disable')"
            />
          </div>
        </div>
      </div>
      <!-- 结算 -->
      <div class="payment" v-if="enable.length">
        <div class="all click">
          <img
            src="../assets/icon/yes-select-two.png"
            v-if="selectAll"
            @click.stop="selectIdList=[]"
          />
          <img src="../assets/icon/no-select-two.png" v-else @click.stop="fnSelectAll" />
          <p v-text="$t('cart.all')">全选</p>
        </div>
        <p>
          {{$t('cart.select')}}
          <span>{{selectIdList.length}}</span>
          {{$t('cart.select_suffix')}}
        </p>
        <div class="payment-botton">
          <div>
            <p v-if="userinfo">{{userinfo.local==1?'¥':'HK$'}}{{cartTotalAmount}}</p>
            <div>{{$t('cart.count')}}({{$t('cart.not_freight')}})</div>
          </div>
          <h2 class="click" v-text="$t('cart.del')" @click.stop="delSelect">删除</h2>
          <p class="click" @click.stop="fnCartSettle" v-text="$t('cart.settle')">结算</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
export default {
  name: "Cart",
  data() {
    return {
      selectIdList: [],
      selectAll: false,
      enable: [],
      disable: [],
      userinfo: null,
      rate: localStorage.getItem("exchangerate"), // 币种汇率
      cartTotalAmount: 0,
      numberChangIng: false
    };
  },
  created() {
    // console.log(this.rate);
    this.getUserInfo();
  },
  watch: {
    selectIdList() {
      this.selectAll = this.selectIdList.length == this.enable.length;
      this.countCartAmount();
    }
  },
  methods: {
    /**
     * 计算当前购物车选中的商品总价
     * @return {[type]} [description]
     */
    countCartAmount() {
      var count = 0;
      eventBus.$emit("RefreshCartNumber");
      var decimal = parseInt(localStorage.getItem("handel_money_precision"));
      for (var i = 0; i < this.selectIdList.length; i++) {
        var id = this.selectIdList[i];
        for (var j = 0; j < this.enable.length; j++) {
          var item = this.enable[j];
          if (item.id == id) {
            // 代表item在cart购物中中
            if (item.price_type == this.userinfo.local) {
              // 币种相同
              count = this.common.add(count, item.total).toFixed(decimal);
            } else {
              // 币种不相同 转换为人民币或港币
              var total = item.total;
              if (this.userinfo.local == 1) {
                // 转换人民币
                total = this.common.div(total, this.rate).toFixed(decimal);
              }
              if (this.userinfo.local == 2) {
                //转换港币
                total = this.common.mul(total, this.rate).toFixed(decimal);
              }
              count = this.common.add(count, total).toFixed(decimal);
            }
          }
        }
      }
      this.cartTotalAmount = count;
    },
    fnSelectAll() {
      var arr = [];
      this.enable.forEach(item => {
        arr.push(item.id);
      });
      this.selectIdList = arr;
    },
    fnSelect(id) {
      this.selectIdList.push(id);
    },
    unSelect(id) {
      var index = this.selectIdList.indexOf(id);
      this.selectIdList.splice(index, 1);
    },
    itemIsSelect(id) {
      return this.selectIdList.indexOf(id) >= 0;
    },
    /**
     * 跳转到商品详情页面
     * @param  {[type]} product_id [商品id]
     * @return {[type]}            [description]
     */
    toProductDetailPage(product_id) {
      this.$router.push({
        path: "/product",
        query: {
          product_id: product_id
        }
      });
    },
    /**
     * 清空失效商品
     */
    clearOverItem() {
      var self = this;
      self.common.httpPost("/api/cart/clearDisable", {}, false).then(ret => {
        if (ret.code == 1) {
          self.disable = [];
        }
      });
    },
    /**
     * 删除选中的购物车项
     */
    delSelect() {
      var self = this;
      if (self.selectIdList.length == 0) return;
      MessageBox({
        title: self.$t("cart.tips"), //提示
        message: self.$t("cart.whether"), //此操作将永久删除该记录, 是否继续?
        confirmButtonText: self.$t("cart.define"), //确定
        showCancelButton: true,
        cancelButtonText: self.$t("cart.cancel"), //取消
        type: "warning",
        callback(action) {
          if (action === "confirm") {
            self.common
              .httpPost("/api/cart/delCart", {
                id: self.selectIdList.toString(),
                number: 0
              })
              .then(ret => {
                if (ret.code == 1) {
                  self.httpMyCartList();
                  self.selectIdList = [];
                  self.selectAll = false;
                  self.cartTotalAmount = 0;
                }
              });
          }
        }
      });
    },
    /**
     * 删除购物车项
     */
    delItem(item, index, listType) {
      var self = this;
      self.common
        .httpPost(
          "/api/cart/delCart",
          {
            id: item.id
          },
          false
        )
        .then(ret => {
          if (ret.code == 1) {
            if (listType == "enable") self.enable.splice(index, 1);
            if (listType == "disable") self.disable.splice(index, 1);
            self.countCartAmount();
          }
        });
    },
    fnNumberBlur: function(index) {
      var self = this;
      var item = this.enable[index];
      if (!item.number) {
        item.number = 1;
        self.common
          .httpPost(
            "/api/cart/editCart",
            {
              id: item.id,
              number: item.number
            },
            false
          )
          .then(ret => {
            if (ret.code == 1) {
              // if (number <= 0) {
              //     self.enable.splice(index, 1);
              // } else {
              //     item.number = number;
              //     if (type == 'add') item.total = self.common.add(item.total, item.price).toFixed(2);
              //     if (type == 'sub') item.total = self.common.sub(item.total, item.price).toFixed(2);
              // }

              item.total = self.common.showPrice(
                self.common.mul(item.price, item.number),
                1,
                1,
                false
              );
              self.countCartAmount();
              self.$set(self.enable, index, item);
            } else self.common.showMessage(ret.msg, "error");

            self.numberChangIng = false;
          });
      }
    },
    /**
     * 购物车数量修改
     * @return {[type]} [description]
     */
    fnNumberChange: function(index) {
      var self = this;
      var item = this.enable[index];
      if (item.number) {
        item.number = String(item.number).replace(/[^\d]/g, "");
      } else {
        return;
      }

      self.common
        .httpPost(
          "/api/cart/editCart",
          {
            id: item.id,
            number: item.number
          },
          false
        )
        .then(ret => {
          if (ret.code == 1) {
            // if (number <= 0) {
            //     self.enable.splice(index, 1);
            // } else {
            //     item.number = number;
            //     if (type == 'add') item.total = self.common.add(item.total, item.price).toFixed(2);
            //     if (type == 'sub') item.total = self.common.sub(item.total, item.price).toFixed(2);
            // }

            item.total = self.common.showPrice(
              self.common.mul(item.price, item.number),
              1,
              1,
              false
            );
            self.countCartAmount();
            self.$set(self.enable, index, item);
          } else {
            self.common.showMessage(ret.msg, "error");
            setTimeout(function() {
              window.location.reload();
            }, 1500);
          }
          self.numberChangIng = false;
        });
    },
    /**
     * 修改个购物车数量
     * @param  {[type]} type  [加/减]
     * @param  {[type]} item  [购物车项]
     * @param  {[type]} index [购物车下标]
     */
    changeItem(type, item, index) {
      var self = this;
      if (self.numberChangIng) return;
      if (type == "add") var number = parseInt(item.number) + 1;
      if (type == "sub") var number = parseInt(item.number) - 1;
      if (number == 0) return;

      self.numberChangIng = true;
      self.common
        .httpPost(
          "/api/cart/editCart",
          {
            id: item.id,
            number: number
          },
          false
        )
        .then(ret => {
          if (ret.code == 1) {
            if (number <= 0) {
              self.enable.splice(index, 1);
            } else {
              item.number = number;
              if (type == "add")
                item.total = self.common.add(item.total, item.price).toFixed(2);
              if (type == "sub")
                item.total = self.common.sub(item.total, item.price).toFixed(2);
            }
            self.countCartAmount();
          } else self.common.showMessage(ret.msg, "error");
          self.numberChangIng = false;
        });
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
      var self = this;
      self.common.httpPost("/api/user/getUserInfo").then(ret => {
        if (ret.code == 1) {
          self.userinfo = ret.data;
          sessionStorage.setItem("userinfo", JSON.stringify(ret.data));
          self.httpMyCartList();
        } else self.showMessage(ret.msg, "error");
      });
    },
    /* 检查产品线是否可以购买 */
    checkGoodsStatus: function(ids, fn) {
      var self = this;
      self.common
        .httpPost("/api/order/checkGoodsStatus", {
          ids: ids
        })
        .then(ret => {
          if (ret.code == 1) {
            fn && fn();
          } else self.common.showMessage(ret.msg, "error");
        });
    },
    /** 检查购物车库存 */
    checkCartStock: function(ids, fn) {
      var self = this;
      self.common
        .httpPost("/api/cart/checkCartStock", {
          ids: ids
        })
        .then(ret => {
          if (ret.code == 1) {
            fn && fn();
          } else {
            MessageBox({
              title: "提示", //提示
              message: "部分商品库存不足,请重新确认",
              confirmButtonText: "确认", //完成支付
              showCancelButton: false,
              type: "warning",
              callback(action) {
                self.$router.go(0);
                // clearInterval(self.inter);
                // self.$router.replace("/order/list");
              }
            });

            // self.common.showMessage(ret.msg, 'error');
            // setTimeout(function() {
            //     self.$router.go(0);
            // }, 1500)
          }
        });
    },
    fnCartSettle() {
      var self = this;
      if (this.selectIdList.length == 0)
        return this.common.showMessage(this.$t("cart.check"), "error"); //请勾选需要结算的商品
      var params = [];
      var cartIdArr = [];
      var specs_id_arr = [];
      for (var i = 0; i < self.selectIdList.length; i++) {
        var id = self.selectIdList[i];
        for (var j = 0; j < self.enable.length; j++) {
          var item = self.enable[j];
          if (item.id == id) {
            cartIdArr.push(item.id);
            specs_id_arr.push(item.specs_id);
            params.push({
              specs_id: item.specs_id,
              number: item.number
            });
          }
        }
      }
      // 检查库存
      self.checkCartStock(cartIdArr.toString(), function() {
        // 检查产品线
        self.checkGoodsStatus(specs_id_arr.toString(), function() {
          self.$router.push({
            path: "/orderConfirm",
            query: {
              params: JSON.stringify(params),
              cart_id: cartIdArr.toString()
            }
          });
        });
      });
    },
    /**
     * 请求我的购物车列表
     */
    httpMyCartList() {
      console.log("httpMyCartList ....");
      var self = this;
      self.common.httpPost("/api/cart/myCartList").then(ret => {
        if (ret.code == 1) {
          self.enable = ret.data.enable;
          self.disable = ret.data.disable;

          for (var i = 0; i < self.enable.length; i++) {
            self.enable[i].price_type = self.enable[i].specs.price_type; // 币种

            // 判断规格中是否有vip价格
            if (self.enable[i].specs.price_vip) {
              self.enable[i].price = self.enable[i].specs.price_vip;
            } else {
              // 判断是否有vip
              if (self.userinfo && self.userinfo.level_discount) {
                self.enable[i].price = self.common.mul(
                  self.enable[i].specs.price,
                  self.userinfo.level_discount
                );
              } else {
                self.enable[i].price = self.enable[i].specs.price;
              }
            }

            self.enable[i].total = self.common.showPrice(
              self.common.mul(self.enable[i].price, self.enable[i].number),
              1,
              1,
              false
            );
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.big-box {
  margin-left: 83px;
  margin-right: 83px;
}

h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(41, 127, 171, 1);
  line-height: 18px;
  margin: 20px 0 15px 18px;
}

.shopping-cart {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 50px;
}

.cart-title {
  height: 50px;
  display: flex;
  align-items: center;
  background: rgba(247, 246, 246, 1);
}

.cart-title div {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.cart-title div img {
  height: 17px;
  width: 17px;
}

.cart-title div p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 10px;
}

.book-name {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 176px;
  margin-right: 190px;
}

.parameter {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-right: 120px;
}

.order {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.order:last-child {
  border-bottom: 0;
}

.choice-botton {
  height: 17px;
  width: 17px;
  margin-right: 23px;
}

.book-photo {
  height: 145px;
  width: 120px;
  margin-right: 75px;
}

.delete {
  height: 12px;
  width: 12px;
  margin-left: 128px;
}

.parameter-body {
  height: 144px;
  display: flex;
  margin-top: 40px;
}

.name-box p {
  width: 160px;
  margin-right: 43px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  margin-bottom: 11px;
}

.name-box p:first-child {
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.name-box div {
  display: inline-block;
  padding: 3px 6px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
}

.parameter-body > p {
  width: 132px;
  font-size: 14px;
  color: rgba(179, 179, 179, 1);
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  margin-right: 52px;
}

.much {
  width: 70px;
}

.much div {
  text-align: center;
  font-size: 14px;
  font-family: "PingFangSC-Medium", sans-serif;
  color: rgba(63, 63, 63, 1);
  line-height: 16px;
  margin-bottom: 1px;
}

.much p {
  text-align: center;
  font-size: 14px;
  font-family: "PingFangSC-Medium", sans-serif;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
}

.reduce-add {
  height: 30px;
  border: 1px solid rgba(230, 230, 230, 0.7);
  display: flex;
  align-items: center;
  margin-left: 60px;
  margin-right: 58px;
}

.reduce-add p,
.reduce-add input {
  height: 30px;
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 30px;
  text-align: center;
  border-left: 1px solid rgba(230, 230, 230, 0.7);
  border-right: 1px solid rgba(230, 230, 230, 0.7);
}

.reduce-add img {
  height: 18px;
  width: 18px;
  margin: 6px;
}

.common-much {
  width: 83px;
}

.common-much div {
  font-size: 18px;
  /* font-weight: bold; */
  color: rgba(41, 127, 171, 1);
  line-height: 18px;
  margin-bottom: 1px;
  text-align: center;
  font-family: "SimHei", "PingFangSC-Medium", sans-serif;
}

.common-much p {
  font-size: 18px;
  /* font-weight: bold; */
  color: rgba(179, 179, 179, 1);
  line-height: 18px;
  text-align: center;
  font-family: "SimHei", "PingFangSC-Medium", sans-serif;
}

.trash {
  margin-bottom: 50px;
}

.trash-title {
  padding-left: 18px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.trash-title > p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
  margin-right: 40px;
}

.trash-title div {
  display: flex;
  align-items: center;
}

.trash-title div img {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

.trash-title div p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 18px;
}

.trash-order {
  border: 1px solid rgba(182, 182, 182, 0.7);
}

.book-photo-box {
  position: relative;
}

.book-photo-box p {
  width: 120px;
  height: 30px;
  background: rgba(168, 168, 168, 0.8);
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 30px;
}

.payment {
  height: 60px;
  margin-bottom: 50px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.all img {
  height: 17px;
  width: 17px;
  margin-right: 10px;
}

.all p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}

.payment > p {
  flex: 1;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 20px;
}

.payment span {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 4px;
  margin-right: 4px;
}

.payment-botton {
  display: flex;
  align-items: center;
}

.payment-botton > div {
  text-align: right;
  margin-right: 20px;
}

.payment-botton div div {
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
}

.payment-botton div p {
  font-size: 22px;
  color: rgba(225, 36, 27, 1);
  line-height: 33px;
  letter-spacing: 1px;
  font-family: "SimHei", "PingFangSC-Medium", sans-serif;
}

.payment-botton > p {
  width: 140px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  background: #297fab;
  text-align: center;
}

h2 {
  width: 120px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  background: #9cc0d2;
  text-align: center;
}
</style>